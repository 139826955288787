
.gift-content {
    &, & * {
        box-sizing: border-box;
    }
    .gift-table {
        height: calc(100% - 92px);
        margin-top: 20px;
    }
    .gift-goods {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .gift-img {
            width: 100px;
            height: 100px;
            margin-right: 20px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
